<template>
  <div class="container-fluid">
    <card class="no-border-card">
      <div class="row cards-grid">
        <router-link
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_INVOICES)
          "
          :to="{ name: 'List ResellerInvoices' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-single-copy-04"></i>
              </span>
              <small>{{ $t("COMMON.RESELLER_INVOICES") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_PAYMENTS)
          "
          :to="{ name: 'List ResellerPayments' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-money-coins"></i>
              </span>
              <small>{{ $t("COMMON.RESELLER_PAYMENTS") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SUBSCRIPTIONS)"
          :to="{ name: 'List Subscriptions' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="fa fa-2x fa-id-card"></i>
              </span>
              <small>{{ $t("COMMON.SUBSCRIPTIONS") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_PRODUCTS)
          "
          :to="{ name: 'List ResellerProducts' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="fa fa-2x fa-cubes"></i>
              </span>
              <small>{{ $t("COMMON.RESELLER_PRODUCTS") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_SERVICES)
          "
          :to="{ name: 'List ResellerServices' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="fa fa-2x fa-cube"></i>
              </span>
              <small>{{ $t("COMMON.RESELLER_SERVICES") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PACKAGES)"
          :to="{ name: 'List Packages' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-box-2"></i>
              </span>
              <small>{{ $t("COMMON.PACKAGES") }}</small>
            </a>
          </div>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
